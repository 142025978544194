@import "../../../../Component/mixin/index";

.bannerBottom__main {
    //max-height: 440px;
    margin-top: 16px;
    position: relative;
    .header-warning-tracking{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    .content-warning-tracking{
        //max-height: 355px;
        overflow-y: auto;
        height: 520px;
        margin-right: -8px;
        padding-right: 8px;

        .content-warning-tracking__item{
            padding: 0.5rem;
            margin-bottom: 0.5rem;
            display: flex;
            align-items: center;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 8px;
            >svg{
                margin-right: 0.5rem;
            }
            .left-content{
                .update-time{
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 0.5rem;
                    }
                }
            }
        }
        .content-warning-tracking__item:last-child{
            margin-bottom: 0;
        }

    }
}


@include desktop_min{
    .content-shipping-tracking{
        background-size: auto 776px;
        height: 776px;
    }
    .header-warning-tracking {
        display: block!important;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        .header-content-left{
            margin-bottom: 8px;
        }
    }
    .bannerBottom__main .content-warning-tracking {
        max-height: 500px;
        overflow-y: auto;
    }
}