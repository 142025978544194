.header-dashboard{
  .header-block{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    .title-header{
      font-weight: 800;
      font-size: 24px;
      line-height: 140%;
    }
    .content-filter{
      .text-filter{
        margin-right: 0.5rem;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #27272A;
      }
      .btn-filter{
        border-radius: 6px!important;
        background: #FFFFFF!important;
        border: 1px solid #EBEEF5!important;
        height: 32px;
        button{
          border: none!important;
          color: #27272A!important;
          &.btn-7-days{
            border-right: 1px solid #EBEEF5!important;
          }
          &.active{
            color: #E5101D!important;
          }
        }
      }
    }
  }
}