@import "src/Component/mixin";


.account {
  //position: absolute;
  background: white;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);

  width: 18.75rem;
  right: 1.875rem;
  top: 2.375rem;

  hr {
    margin: .75rem 1.25rem;
    border: none;
    border-top: 1px solid var(--bg-hr);
  }

  ul {
    margin: 0 1.25rem;
    padding-bottom: .75rem;

    li {
      padding: .625rem .75rem;
      cursor: pointer;
      font-weight: 500;
      font-size: 0.935rem;
      line-height: 1.25rem;
      color: var(--color-li-up2022-7);

      transition: all .3s ease;
      overflow: hidden;
      border: none;
    }

    li:hover {
      background: #F2F9FD;
      color: var(--color-package-up2022-7);
      border-radius: var(--border-radius-component);
    }

    li:active {
      color: var(--white-color);
      background: var(--button-color);
    }

    .log_out {
      margin-top: 1.125rem;
    }

    .log_out:before {
      content: "";
      width: 16.25rem;
      height: .125rem;
      border-top: 1px solid var(--bg-hr);
      position: absolute;
      bottom: 3.75rem;
      left: 1.3125rem;
    }
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(18.75rem);
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(18.75rem);
  }
}

.box_modal-account {
  .icon-close {
    @include desktop_width_1366 {
      top: 3.5rem;
      right: 31rem;
      padding: 0.1rem 0.3rem;
    }

    @include desktop_width_1280 {
      top: 3.5rem;
      right: 30.5rem;
      padding: 0.1rem 0.3rem;
    }
    animation: fadeInRight .3s ease-in-out;
    position: fixed;
    top: 3.3rem;
    right: 31rem;
    cursor: pointer;
    padding: 0.2rem .3rem;


    @include desktop_min {
      top: 3.45rem;
      padding: .1rem .25rem;
    }
  }

  .customer-management {
    position: relative;
    animation: fadeInRight .3s ease-in-out;
    background: white;
    position: absolute;
    top: 3.47rem;
    right: 0;
    padding: 24px 16px;

    width: 31.25rem;
    height: calc(100% - 3.47rem);
    overflow-y: scroll;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;

    -webkit-transition: .3s;
    transition: .3s;

    .scroll-custom::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .scroll-custom::-webkit-scrollbar {
      width: calc(0.5rem);
      background-color: transparent !important;
    }

    .scroll-custom::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: none;
      background-color: #EDEFF2;
    }

    @media screen and (max-width: 1280px) {
      top: 3.55rem;
    }

    &__box {
      margin-bottom: 24px;
      flex-grow: 0;
    }

    &__account-wrapper {

      display: flex;
      flex-direction: column;
      gap: 16px;

      overflow-y: auto;
      height: fit-content;
      max-width: 480px;
    }

    &__account {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      background: #F5F6F8;
      padding: 16px;
      max-width: 468px;
    }

    &__title {
      display: grid;
      margin-left: 8px;
    }

    &__add{
      display: flex;
      align-items: center;
      border-radius: 8px;
      background: #F5F6F8;
      justify-content: center;
      margin: 24px 0 0;
      cursor: pointer;

      &:hover {
        background: #EDEFF2;
      }
    }

    &__footer {
      flex-grow: 0;
    }

    &__logout{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      cursor: pointer;

      &:hover{
        opacity: 0.5;
      }
    }
  }
}

/* Reponsive */
@media (max-width: 1366px) {
  html {
    font-size: .8rem;
  }
}

@include desktop_width_1280 {
  .box_confirm_account {
    width: 480px;
    max-height: 196px;
  }
}

@include desktop_width_1366 {
  .box_confirm_account {
    width: 480px;
    max-height: 196px;
  }

}

@media screen and (max-width: 1366px) {
  .store-account_upload-camera {
    top: 53px !important;
    right: -9px !important;
  }
}

//@include