@import "src/Component/mixin";

.wrapper {
  margin-right: 2rem;

  .icon_avatar {
    cursor: pointer;
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
  }
}

.tooltipHeight {
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account {
  background: white;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);

  width: 20.375rem;

  hr {
    margin: .75rem 1.25rem;
    border: none;
    border-top: 1px solid var(--bg-hr);
  }

  ul {
    padding-bottom: .75rem;
    // border-top: 1px solid var(--bg-hr);

    li {
      padding: 8px;
      cursor: pointer;
      font-weight: 500;
      font-size: 0.935rem;
      line-height: 1.25rem;
      // eslint-disable-next-line
      color: var(--color-li-up2022-7);
      transition: all .3s ease;
      overflow: hidden;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 12px;

      //svg {
      //  margin-right: 4px;
      //}

      &[data-active=true] {
        color: var(--color-package-up2022-7) !important;
        background: rgba(255, 66, 79, 0.10);

        svg {
          path {
            fill: var(--color-package-up2022-7);

            &:nth-child(2) {
              fill: none;
              stroke: var(--color-package-up2022-7);
            }
          }
        }
      }
    }

    li:hover {
      border-radius: 12px;
      background: #E0E0E0;

    }

    li:active {
      color: var(--white-color);
      background: rgba(255, 66, 79, 0.10);

      svg {
        path {
          fill: var(--color-package-up2022-7);

          &:nth-child(2) {
            fill: none;
            stroke: var(--color-package-up2022-7);
          }
        }
      }
    }

    a {
      cursor: pointer;
      font-weight: 500 !important;
      font-size: 0.935rem !important;
      line-height: 1.25rem !important;
      // eslint-disable-next-line
      color: var(--color-li-up2022-7) !important;
      transition: all .3s ease;
      overflow: hidden;
      border: none;

      &[data-active=true] {
        color: var(--color-package-up2022-7) !important;
        background: rgba(255, 66, 79, 0.10);

        svg {
          path {
            fill: var(--color-package-up2022-7);

            &:nth-child(2) {
              fill: none;
              stroke: var(--color-package-up2022-7);
            }
          }
        }
      }

      svg {
        margin-right: 4px;
      }
    }

    a:hover {
      // background: rgba(255, 66, 79, 0.10);
      // color: var(--color-package-up2022-7) !important;
      // border-radius: var(--border-radius-component) !important;
      border-radius: 12px;
      background: #F5F6F8;

      svg {
        path {
          fill: var(--color-package-up2022-7);

          &:nth-child(2) {
            fill: none;
            stroke: var(--color-package-up2022-7);
          }
        }
      }
    }

    a:active {
      color: var(--white-color) !important;
      background: rgba(255, 66, 79, 0.10);

      svg {
        path {
          fill: var(--color-package-up2022-7);

          &:nth-child(2) {
            fill: none;
            stroke: var(--color-package-up2022-7);
          }
        }
      }
    }
  }

  .group {
    display: flex;
    padding: 1rem;

    flex-direction: column;
    align-items: center;

    img {
      width: 3.5rem;
      height: 3.5rem;
      margin: .2rem 1.125rem 0 0;
      margin-bottom: 12px;
    }

    .inner {
      .name {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: var(--color-tilte-up2022-7);

        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        height: 1.2em;
        white-space: nowrap;
      }

      .email {
        font-weight: 400;
        font-size: .9375rem;
        line-height: 1.25rem;
        color: var(--color-subtilte-up2022-7);
        //margin-bottom: .25rem;

        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        //height: 1.2em;
        white-space: nowrap;
      }

      .package {
        font-weight: 600;
        font-size: .875rem;
        line-height: 1.25rem;
        color: #FF9F41;
        padding: 0 .625rem;
        border-radius: var(--border-radius-component);
        background: rgba(255, 159, 65, 0.1);
        width: fit-content;
      }
    }
  }

  .group_info {
    display: flex;
    // padding: 1rem 1.25rem 0.4rem 1.25rem;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 90%;

    .avatar {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }

    .inner {
      width: 64%;

      .name {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: var(--color-tilte-up2022-7);

        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        height: 1.2em;
        white-space: nowrap;
      }

      .email {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.25rem;
        color: var(--color-subtilte-up2022-7);
        //margin-bottom: .25rem;

        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        //height: 1.2em;
        white-space: nowrap;
      }

      .package {
        font-weight: 600;
        font-size: .875rem;
        line-height: 1.25rem;
        color: #FF9F41;
        padding: 0 .625rem;
        border-radius: var(--border-radius-component);
        background: rgba(255, 159, 65, 0.1);
        width: fit-content;
      }
    }
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(18.75rem);
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(18.75rem);
  }
}

.box_modal {
  .dismiss {
    width: 1.5rem;
    height: 1.5rem;
    animation: fadeInRight .3s ease-in-out;
    position: fixed;
    top: 3.5rem;
    right: 31.25rem;
    cursor: pointer;
    background: rgba(124, 142, 160, 1);
    padding: 0.2rem .3rem;

    svg {
      height: 1rem;
      width: 1rem;
    }

    @include desktop_min {
      top: 3.45rem;
      padding: .1rem .25rem;
    }
  }

  .general {

    animation: fadeInRight .3s ease-in-out;
    background: white;
    position: absolute;
    top: 3.47rem;
    right: 0;

    width: 31.25rem;
    height: 100%;
    overflow-y: scroll;

    -webkit-transition: .3s;
    transition: .3s;

    padding-bottom: 3.125rem;

    .bgp {
      height: 6.0625rem;
    }

    .grp_avatar {
      width: 6rem;
      height: 6rem;
      border: .1875px solid var(--white-color);
      border-radius: 50%;
      background: #F9F9FB;

      position: absolute;
      top: 2.625rem;
      left: 13rem;
      //box-shadow: 0 2px 2px rgba(0, 0, 0, .05);
      cursor: pointer;

      .avatar {
        width: 6rem;
        height: 6rem;
      }

      span:before {
        content: "";
        width: 1rem;
        height: 1rem;
        background: var(--plum-color);
        position: absolute;
        bottom: 0;
        right: 7px;
        border-radius: 50%;
      }
    }

    .content {
      margin-top: 3.25rem;
      text-align: center;

      .info {
        .name {
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: #222222;
          margin-bottom: 8px;

          text-overflow: ellipsis;
          overflow: hidden;
          height: 1.2em;
          white-space: nowrap;
          max-width: 31.25rem;
        }

        .package {
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          color: #1E9A98;
          display: flex;

          padding: 4px 10px;
          background: rgba(43, 184, 169, 0.1);
          width: fit-content;
          margin: 0 auto;
          border-radius: var(--border-radius-component);
          margin-bottom: 8px;

          svg {
            margin: .0625rem .3125rem;
          }
        }

        .duration {
          position: relative;
          display: flex;
          justify-content: center;

          a {
            margin-right: 8px;
            cursor: pointer;
            color: var(--color-link-up2022-7);
          }

          svg {
            margin-top: 8px;

            path {
              fill: var(--color-link-up2022-7);
            }
          }

          .list {
            position: absolute;
            left: 10.5rem;
            z-index: 2;
            background: var(--white-color);
            border-radius: var(--border-radius);
            padding: 12px 10px;
            box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
            top: 24px;

            a {
              padding: 12px 10px;
              text-align: left;
              display: block;
              color: var(--color-li-up2022-7);
              margin: 0;
            }

            a:hover {
              background: rgba(255, 66, 79, 0.10);
              border-radius: var(--border-radius-component);
              color: var(--color-hover-package-up2022-7);
            }
          }
        }
      }
    }

    &.hide {
      display: none;
    }
  }
}

.grp_time {
  color: var(--white-color);
  margin: .5rem .75rem;

  p {
    font-weight: 500;
    font-size: .8125rem;
    line-height: 1rem;
    margin-bottom: .5rem;
  }
}

.modal_custom {
  animation: fadeInLeft .3s ease-in-out;
  height: 100%;
}

.box_confirm {
  background: white;
  margin: 20rem auto 0 auto;
  padding: 32px 24px;
  line-height: 140%;

  width: 24.75rem;
  max-height: 13rem;
  border-radius: var(--border-radius);

  .grp_btn {
    margin-top: 1.5rem;
    text-align: right;

    button {
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;

      cursor: pointer;
      padding: 8px 24px;
      border-radius: var(--border-radius-component);
      border: 1px solid #edf0f6;
    }

    .dismiss {
      background: var(--white-color);
      margin-right: .75rem;

      &:hover {
        border: 1px solid var(--color-package-up2022-7);
      }
    }

    .save {
      color: var(--white-color);
      background: var(--bg-checked-radio);
      background: var(--color-package-up2022-7);

      &:hover {
        background: var(--color-hover-package-up2022-7);
        color: var(--white-color);
      }
    }
  }
}

/* Reponsive */
@media (max-width: 1366px) {
  html {
    font-size: .8rem;
  }
}