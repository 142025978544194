.content-language {
  width: 134px;
  .item-language {
    cursor: pointer;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: #C40B27;
    }

    &[ data-active = true ] {
      color: #C40B27;
    }
  }
}