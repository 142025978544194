.comfirm-info {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #0b101a;

  label {
    font-weight: 400;
    line-height: 140%;
  }

  .confirm-info__region-label {
    padding: 0 2px;
    margin-bottom: 8px;
  }

  .confirm-info__region {
    display: flex;
    gap: 10px;

    input {
      background: #f3f3f4;
      height: 44px;
    }

    .input__icon {
      bottom: 11px;
    }

    .alternative-auto-complete {
      &__menu {
        &[data-reverse-position='true'] {
          top: unset;
          bottom: 50px;
        }

        .input__input {
          background: unset;
          height: 32px;
        }

        .input__icon {
          bottom: 7px;
        }
      }
    }
  }

  #username,
  #shopname,
  #password,
  #phone-number {
    height: 44px;
  }

  #phone-number {
    color: #191D32;
    opacity: 0.7;
  }

  &__group-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    margin: 0 auto;

    &::after {
      background: rgba(0, 0, 0, 0) linear-gradient(to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.3) 100%) repeat scroll 0 0;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      right: -75%;
      top: 0;
      transform: skewX(-25deg);
      width: 70%;
      z-index: 2;
      animation: 3s ease 0s normal none 10 running fixedAnim;
      animation: 3s ease 0s normal none 10 running fixedAnim;
      -webkit-animation: 3s ease 0s normal none 10 running fixedAnim;
      -moz-animation: 3s ease 0s normal none 10 running fixedAnim;
    }
  }

  @-webkit-keyframes fixedAnim {
    0% {
      right: 125%;
    }

    125% {
      right: 0;
    }
  }

  @-moz-keyframes fixedAnim {
    0% {
      right: 125%;
    }

    125% {
      right: 0;
    }
  }

  @keyframes fixedAnim {
    0% {
      right: 125%;
    }

    125% {
      right: 0;
    }
  }

  &__title-login-form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 40px;

    p {
      font-weight: 700;
      font-size: 32px;
      line-height: 140%;
      color: #000028;
      margin-bottom: 8px;
    }

    span {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      color: #000028;
    }
  }

  &__input-field {
    width: 100%;
    height: 96px;
    position: relative;

    .otp {
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      color: #1a94ff;

      position: absolute;
      right: 1rem;
      cursor: pointer;
    }

    .otp-input {
      margin-top: 18px;
      margin-bottom: 8px;
    }

    .otp-60s {
      color: #9e9ea7;
    }

    .send-otp-disabled {
      color: #9e9ea7;
      cursor: unset;
    }

    .icon-show-pass,
    .icon-hide-pass {
      position: absolute;
      right: 1rem;
      top: 2.4rem;
      transition: cubic-bezier(0.075, 0.82, 0.165, 1);
      animation-name: icon-opacity;
      animation-duration: 1s;
      cursor: pointer;
    }

    .border-red-input {
      border: 0.5px #ee4064 solid !important;
    }

    &:focus {
      outline: none;
      border: 1px solid #1a94ff !important;
      box-shadow: 0px 0px 0px 3px rgb(97 162 247 / 57%);
    }

    .number-box {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;

      input {
        pointer-events: none;

        &:focus {
          pointer-events: none;
          outline: none;
          border: 1px solid #1a94ff !important;
          box-shadow: 0px 0px 0px 3px rgb(97 162 247 / 57%);
        }
      }
    }
  }

  &__register-link {
    font-size: 14px;
    line-height: 140%;
    color: #0d0c22;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 0.5rem;
  }

  &__phone-call {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    text-align: right;
    color: #0b101a;

    a {
      color: #0b101a;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__button-confirm {
    border: none !important;
    height: 52px !important;
    background: #e5101d !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 140% !important;
    color: #ffffff !important;
    cursor: pointer !important;
    animation-name: opacityAnim !important;
    animation-duration: 1s !important;
    opacity: 1 !important;
    margin-top: 16px !important;
    /* animation-name: slideFromRight;
        animation-duration: 1s; */
    /* opacity: 0.65; */

    &:focus {
      outline: none;
    }

    &:hover {
      background: rgba(255, 0, 0, 0.6) !important;
      opacity: 1;
    }

    &:disabled {
      background: linear-gradient(0deg,
          rgba(244, 247, 252, 0.98),
          rgba(244, 247, 252, 0.98)),
        #00081d;
      color: rgba(0, 0, 0, 0.15);
      cursor: unset;
    }

    .active {
      background: #e20000 !important;
      /* opacity: 0.7 !important; */
    }
  }

  .checkbox {
    margin-right: 13px;
  }

  &__contact-login-form {
    background: #FFFFFF;
    border-radius: 60px;
    width: 230px;
    height: 41px;
    position: absolute;
    bottom: 32px;
    left: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1440px) {

  #username,
  #shopname,
  #phone-number,
  #address,
  #password {
    width: 100% !important;
    height: 36px !important;
  }

  .input__button {
    button {
      height: 36px !important;
    }
  }

  .confirm-info__region {
    margin-top: 9px;

    input {
      height: 36px !important;
    }
  }

  .confirm-info__group-region {
    margin-top: 49px;
  }

  .comfirm-info__button-confirm {
    height: 40px !important;
  }

  .chevron-left-double-svg {
    height: 40px !important;
  }




  .comfirm-info {
    &__input-field {
      height: 81px;
    }

    &__group-logo {
      margin-top: 1rem;

      img {
        width: 100px;
      }
    }

    &__title-login-form {
      margin-bottom: 0.5rem;

      p {
        font-size: 24px;
      }

      span {
        font-size: 15px;
      }
    }

    &__button-confirm {
      width: 374px;
    }

    &__register-link {
      margin-top: 2rem;
    }
  }
}