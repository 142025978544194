.slick-slider{
    .slick-dots{
        bottom:8px;
        li{
            button{
                width: 12px;
                height: 6px;
                background: rgba(255, 255, 255, 0.6);
                border-radius: 60px;
                padding: 0;
                &::before{
                    content: '';
                }
            }
            margin: 0 2px !important;
            width: fit-content;
        }
        .slick-active{
            background: white;
            width: 24px;
            height: 6px;
            border-radius: 60px;
        }
    }
}
.banner-items{
    width: 385px;
    height: 192px;
    border-radius: 8px;
    .product-image{
        border-radius: 8px;
        height: 100%;
        width: 100%;
    }
}
.popup-items{
    position: relative;
    width: 46.9%;
    height: 63.5%;
    .product-image_popup{
        width: 100%;
        height: 100%;
    }
    .popup-items_close{
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }
}
